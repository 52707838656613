<template>
  <section id="pricing-plan">
    <section id="knowledge-base-search">
      <b-card
        no-body
        class="knowledge-base-bg text-center"
        :style="{
          backgroundImage: `url(${require('@/assets/images/banner/banner.png')})`,
        }"
      >
        <b-card-body class="card-body">
          <h2 class="text-primary mt-2">Pricing Packages</h2>
          <b-card-text class="mb-2">
            <span>
              <!-- <br /> -->
            </span>
          </b-card-text>
          <b-card-text class="mb-2">
            <!-- <span>Popular searches: </span> -->
            <!-- <span class="font-weight-bolder"
              >Sales automation, Email marketing</span
            > -->
          </b-card-text>
        </b-card-body>
      </b-card>
    </section>

    <section>
      <!-- content -->
      <b-row class="pricing-card match-height">
        <b-col
          offset-sm-2
          sm="10"
          md="10"
          offset-lg="2"
          lg="10"
          class="mx-auto"
        >
          <b-row class="match-height">
            <b-col
              v-for="item in packageDatas"
              :key="item.id"
              md="4"
              sm="6"
              class="mt-1"
              @click="selected = item.id"
              :style="
                selected === item.id
                  ? 'box-shadow: rgb(248 248 248) 0px 1px 1px inset, rgb(115 103 240) 0px 5px 30px;'
                  : ''
              "
            >
              <b-card class="text-center mt-1" img-top @click="">
                <b-row>
                  <b-col
                    sm="12"
                    v-for="(img, index) in item.image_datas"
                    :key="index"
                  >
                    <b-img
                      :src="img.image_url"
                      class="mb-2 mt-5 img-fluid"
                      alt="Responsive image"
                    />
                  </b-col>
                </b-row>

                <h3>{{ item.name }}</h3>
                <b-card-text>
                  <b>{{ item.amount }}</b>
                  Credit</b-card-text
                >
                <hr />
                <div class="annual-plan">
                  <div class="plan-price mt-2">
                    <sup class="font-medium-1 font-weight-bold text-primary"
                      >$</sup
                    >
                    <span
                      class="
                        pricing-basic-value
                        font-weight-bolder
                        text-primary
                      "
                      >{{ withCommas(item.price) }}</span
                    >
                    <!-- <sub
                      class="
                        pricing-duration
                        text-body
                        font-medium-1 font-weight-bold
                      "
                      >/{{ withCommas(item.amount, 0) }}</sub
                    > -->
                  </div>
                </div>

                <!-- <b-list-group class="list-group-circle text-left">
                  <b-list-group-item>
                    Toll {{ withCommas(item.amount, 0) }}</b-list-group-item
                  >
                </b-list-group> -->

                <b-card-text class="mt-1">
                  <b-list-group class="list-group-circle text-left">
                    <b-list-group-item>
                      {{ item.detail }}
                    </b-list-group-item>
                  </b-list-group>
                </b-card-text>

                <b-form-radio
                  v-show="false"
                  v-model="selected"
                  plain
                  name="some-radios3"
                  :value="item.id"
                >
                  Select
                </b-form-radio>
              </b-card>
              <pay-pal
                v-if="selected === item.id"
                @paypal-callback="handleSubmit"
                :totalPrice="item.price * 1"
                :description="item.name"
                @loaded="isLoading = $event"
                class="mt-1"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </section>
    <!--/ pricing faq -->
  </section>
</template>

<script>
import { mapActions } from "vuex"
import Loading from "vue-loading-overlay"
import "vue-loading-overlay/dist/vue-loading.css"
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue"
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue"
import Ripple from "vue-ripple-directive"
/* eslint-disable global-require */
export default {
  components: {
    Loading,
    FormBuyPackage: () => import("./FormBuyPackage.vue"),
    AppCollapseItem,
    AppCollapse,
  },
  watch: {},
  directives: {
    Ripple,
  },
  data() {
    return {
      selected: "",
      packageDatas: [],
      isLoading: false,
      fullPage: true,
    }
  },
  created() {
    this.fetchPackageData()
  },
  methods: {
    ...mapActions("GlobalUserData", ["ac_fetchCredit"]),
    async handleSubmit(paypal) {
      try {
        const form = this.cp(
          this.packageDatas.find((data) => data.id == this.selected)
        )
        form.package_data_id = form.id
        form.paypal = paypal

        await this.api.post("/api/packageCheckout/checkout", form)

        // globalUserData
        await this.ac_fetchCredit()
        this.alert.success({ text: "" })
        this.switch("hide")
      } catch (error) {
        console.error(error)
        return ""
      }
    },

    async fetchPackageData() {
      try {
        this.packageDatas = await this.api.get(`api/packages`)
        this.packageDatas = this.packageDatas.map((data) => {
          data.selected = ""
          return data
        })
      } catch (error) {}
    },
    clickBTNBuy(data) {
      this.$refs.FormBuyPackage.show(data)
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
// @import "@core/scss/vue/pages/page-knowledge-base.scss";
@import "@core/scss/vue/pages/page-pricing.scss";
</style>
